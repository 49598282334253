import React from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"

const BrandsCarousel = ({ data }) => {
  return (
    <Carousel
      interval={10000}
      transitionTime={500}
      infiniteLoop={true}
      autoPlay={true}
      dynamicHeight={true}
      centerMode={true}
      centerSlidePercentage={100 / data.length}
      cancelClick={true}
      autoFocus={false}
      showThumbs={false}
      swipeable={false}
      showIndicators={false}
      emulateTouch={false}
      showArrows={false}
      stopOnHover={false}
    >
      {data.map(image => (
        <div onClick={() => window.open(image.description)} key={image.id}>
          <div
            className="brand-image"
            style={{
              backgroundImage: `url(${image.fluid.src})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              left: "50%",
            }}
          ></div>
        </div>
      ))}
    </Carousel>
  )
}

export default BrandsCarousel
