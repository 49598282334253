import React from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"

const HeroCarousel = ({ data }) => {
  return (
    <Carousel
      interval={10000}
      transitionTime={500}
      infiniteLoop={true}
      autoPlay={true}
      dynamicHeight={false}
      autoFocus={false}
      centerMode={false}
      showThumbs={false}
      swipeable={false}
      showIndicators={false}
      emulateTouch={false}
      showArrows={false}
      stopOnHover={false}
    >
      {data.map(image => (
        <div className="home-hero-container" key={image.id}>
          <img className="home-hero" src={image.fluid.src} alt="hero" />
          <div className="home-hero-header-container">
            <h1 className="home-header">{image.title}</h1>
            <h2 className="home-header">{image.description}</h2>
          </div>
        </div>
      ))}
    </Carousel>
  )
}

export default HeroCarousel
