import WarehouseMap from "../../static/Warehouse 3D_SIND_w Logos.png"
import React from "react"
import ImageMapper from "react-image-mapper"
import styled from "styled-components"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"

class Mapper extends React.Component {
  state = {
    img: WarehouseMap,
    modal: false,
    area: "",
  }
  enterArea = area => {
    if (area.id === "1") {
      this.setState({
        img: WarehouseMap,
        modal: true,
        area: area,
      })
    } else if (area.id === "2") {
      this.setState({
        img: WarehouseMap,
        modal: true,
        area: area,
      })
    } else if (area.id === "3") {
      this.setState({
        img: WarehouseMap,
        modal: true,
        area: area,
      })
    } else if (area.id === "4") {
      this.setState({
        img: WarehouseMap,
        modal: true,
        area: area,
      })
    } else if (area.id === "5") {
      this.setState({
        img: WarehouseMap,
        modal: true,
        area: area,
      })
    } else if (area.id === "6") {
      this.setState({
        img: WarehouseMap,
        modal: true,
        area: area,
      })
    }
  }

  leaveArea = area => {
    this.setState({
      img: WarehouseMap,
      modal: false,
      area,
    })
  }

  clicked = area => {
    if (area.id === "1") {
      this.setState({
        img: WarehouseMap,
        modal: !this.state.modal,
        area: area,
      })
    } else if (area.id === "2") {
      this.setState({
        img: WarehouseMap,
        modal: true,
        area: area,
      })
    } else if (area.id === "3") {
      this.setState({
        img: WarehouseMap,
        modal: true,
        area: area,
      })
    } else if (area.id === "4") {
      this.setState({
        img: WarehouseMap,
        modal: true,
        area: area,
      })
    } else if (area.id === "5") {
      this.setState({
        img: WarehouseMap,
        modal: true,
        area: area,
      })
    } else if (area.id === "6") {
      this.setState({
        img: WarehouseMap,
        modal: true,
        area: area,
      })
    }
  }

  render() {
    const WarehouseMap = {
      name: "sammons",
      areas: this.props.mapData,
    }

    const Popup = styled.div`
      position: absolute;
      content: "";
      top: ${({ top }) => (top ? `${top}px` : "0px")};
      left: ${({ left }) => (left ? `${left}px` : "0px")};
      z-index: 10;
      border: 1px solid var(--gray);
      border-radius: 8px;
      display: ${({ modal }) => (modal ? "flex" : "none")};
      background-color: var(--white);
      padding: 20px;
      width: 300px;

      .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
          font-size: 14px;

          a {
            background-color: var(--gold);
            padding: 10px 15px;
            color: #000;
            border-radius: 8px;
          }
        }
      }

      @media (max-width: 716px) {
        top: 0;
        left: 0;
        width: 100%;
      }
    `

    const Marker = styled.div`
      position: absolute;
      content: "";
      top: ${({ top }) => (top ? `${top}px` : "0px")};
      left: ${({ left }) => (left ? `${left}px` : "0px")};
      z-index: 2;
      border: 1px solid var(--primary);
      border-radius: 50px;
      display: flex;
      background-color: var(--primary);
      opacity: 75%;
      width: 25px;
      height: 25px;
      cursor: pointer;
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        opacity: 1;
        border-radius: 100%;
        border: 3px solid var(--primary);
        background-color: transparent;
        transform: translate(-50%, -50%);
        animation: warehouseMarkerRadiate 2s 1s infinite;
        @keyframes warehouseMarkerRadiate {
          0% {
            width: 0;
            height: 0;
          }
          50% {
            opacity: 1;
          }
          100% {
            width: 200%;
            height: 200%;
            opacity: 0;
          }
        }
      }
    `

    const CloseModal = styled.button`
      position: absolute;
      top: 15px;
      right: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 20px;
      height: 20px;
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      z-index: 10;

      div {
        width: 20px;
        height: 2px;
        border-radius: 8px;
        transform-origin: 4px;
        background: #000;
        position: relative;
        transition: opacity 300ms, transform 300ms;

        :first-child {
          transform: rotate(45deg);
        }
        :nth-child(2) {
          transform: rotate(-45deg);
        }
      }
    `

    const options = {
      renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: node => {
          const { gatsbyImageData, description } = node.data.target
          return (
            <div
              style={{ display: "flex", marginBottom: "15px", height: "100px" }}
            >
              <GatsbyImage
                objectFit="contain"
                image={getImage(gatsbyImageData)}
                alt={description}
              />
            </div>
          )
        },
        [INLINES.HYPERLINK]: (node, children) => {
          const { uri } = node.data
          return (
            <a href={uri} target="">
              {children}
            </a>
          )
        },
      },
    }

    return (
      <>
        <div
          className="warehouse-map"
          style={{ position: "relative", width: this.props.windowWidth }}
        >
          <ImageMapper
            src={this.state.img}
            map={WarehouseMap}
            onClick={area => this.clicked(area)}
            onMouseEnter={area => this.enterArea(area)}
            onMouseLeave={area => this.leaveArea(area)}
            width={this.props.windowWidth}
            imgWidth={7680}
          />
          {this.props.mapData.map(x => (
            <Marker
              key={x.id}
              top={x?.markerTop}
              left={x?.markerLeft}
              onMouseEnter={() => this.enterArea(x)}
              onMouseLeave={() => this.leaveArea(x)}
              onClick={() => this.clicked(x)}
            />
          ))}
          <Popup
            key={this.id}
            top={this.state.area?.top}
            left={this.state.area?.left}
            modal={this.state.modal}
          >
            <CloseModal
              onClick={() =>
                this.setState({
                  modal: false,
                })
              }
            >
              <div></div>
              <div></div>
            </CloseModal>
            {this.state.area.logo?.raw !== undefined ? (
              <div className="wrapper">
                {renderRichText(this.state.area?.logo, options)}
              </div>
            ) : (
              <></>
            )}
          </Popup>
        </div>
      </>
    )
  }
}

export default Mapper
