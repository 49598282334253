import React from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'

const BrandsCarouselMobile = ({ data }) => {
    return (
        <Carousel
            interval={10000}
            transitionTime={500}
            infiniteLoop={true}
            autoPlay={true}
            dynamicHeight={true}
            cancelClick={true}
            autoFocus={false}
            centerMode={false}
            showThumbs={false}
            swipeable={false}
            showIndicators={false}
            emulateTouch={false}
            showArrows={false}
            stopOnHover={false}
        >
            {data.map(image => (
                <a href={image.description} target="_blank" rel="noopener">
                    <div
                        key={image.id}
                        className="brand-image"
                        style={{
                            backgroundImage: `url(${image.fluid.src})`,
                            backgroundSize: "auto 70px",
                            backgroundRepeat: "no-repeat",
                            left: "50%",
                        }}
                    >
                    </div>
                </a>
            ))}
        </Carousel>
    );
};

export default BrandsCarouselMobile;