import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroCarousel from "../components/HeroCarousel"
import BrandsCarousel from "../components/BrandsCarousel"
import BrandsCarouselMobile from "../components/BrandsCarouselMobile"
import { Link } from "gatsby"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Counter from "../components/counter"
import WarehouseMap from "../components/warehousemap"

const IndexPage = ({ data: { allContentfulHome } }) => {
  const homeData = allContentfulHome.edges[0].node
  const [mapData, setMapData] = React.useState([])
  const [windowWidth, setWindowWidth] = React.useState(1264)

  React.useEffect(() => {
    typeof window !== undefined && window.innerWidth > 1264
      ? setWindowWidth(1264)
      : setWindowWidth(window.innerWidth - 100)
  }, [])

  const scale = windowWidth ? windowWidth / 7680 : 1

  React.useEffect(() => {
    setMapData([
      {
        id: "1",
        title: "SitePro Rentals",
        shape: "circle",
        name: "SitePro Rentals",
        fillColor: "transparent",
        strokeColor: "transparent",
        lineWidth: "0",
        coords: [430, 1450, 1],
        logo: homeData.sitePro,
        left: scale * 430,
        top: scale * 1450,
        markerLeft: scale * 430,
        markerTop: scale * 1450,
        active: true,
      },
      {
        id: "2",
        title: "Precision Warehouse Design",
        shape: "circle",
        name: "Precision Warehouse Design",
        fillColor: "transparent",
        strokeColor: "transparent",
        lineWidth: "0",
        coords: [2758, 2405, 1],
        logo: homeData.pwd,
        left: scale * 2758,
        top: scale * 2405,
        markerLeft: scale * 2758,
        markerTop: scale * 2405,
        active: true,
      },
      {
        id: "3",
        title: "Briggs Equipment",
        shape: "circle",
        name: "Briggs Equipment",
        fillColor: "transparent",
        strokeColor: "transparent",
        lineWidth: "0",
        coords: [4330, 2780, 1],
        logo: homeData.briggsEquipment,
        left: scale * 4330,
        top: scale * 2780,
        markerLeft: scale * 4330,
        markerTop: scale * 2780,
        active: true,
      },
      {
        id: "4",
        title: "Briggs Truck and Rail",
        shape: "circle",
        name: "Briggs Truck and Rail",
        fillColor: "transparent",
        strokeColor: "transparent",
        lineWidth: "0",
        coords: [5920, 3296, 1],
        logo: homeData.briggsTruckAndRail,
        left: scale * 5920 - 150,
        top: scale * 3296,
        markerLeft: scale * 5920,
        markerTop: scale * 3296,
        active: true,
      },
      {
        id: "5",
        title: "Briggs Earth and AG",
        shape: "circle",
        name: "Briggs Earth and AG",
        fillColor: "transparent",
        strokeColor: "transparent",
        lineWidth: "0",
        coords: [6903, 1613, 1],
        logo: homeData.briggsEarthAndAg,
        left: scale * 6903 - 200,
        top: scale * 1613,
        markerLeft: scale * 6903,
        markerTop: scale * 1613,
        active: true,
      },
      {
        id: "6",
        title: "Sync Storage",
        shape: "circle",
        name: "Sync Storage",
        fillColor: "transparent",
        strokeColor: "transparent",
        lineWidth: "0",
        coords: [3972, 959, 1],
        logo: homeData.syncStorage,
        left: scale * 3972 - 150,
        top: scale * 959,
        markerLeft: scale * 3972,
        markerTop: scale * 959,
        active: true,
      },
    ])
  }, [scale])

  return (
    <Layout>
      <SEO title="Home" />
      <div className="home page-container">
        <div className="page-summary">
          <HeroCarousel
            showThumbs={false}
            data={homeData.heroHeader.references}
          />
          <div className="home-brands desktop-slider">
            <BrandsCarousel showThumbs={false} data={homeData.heroBrands} />
          </div>
          <div className="home-brands mobile-slider">
            <BrandsCarouselMobile
              showThumbs={false}
              data={homeData.heroBrands}
            />
          </div>
        </div>
        <div className="warehouse-container">
          <WarehouseMap mapData={mapData} windowWidth={windowWidth} />
        </div>
        <div className="container-summary">
          {renderRichText(homeData.missionStatement, {
            renderNode: {
              [BLOCKS.HEADING_1]: (node, children) => (
                <h1 className="home-header">{children}</h1>
              ),
              [BLOCKS.PARAGRAPH]: (node, children) => (
                <p className="section-summary align-center">{children}</p>
              ),
            },
          })}
          <Link className="learn-more-button" to="/about">
            Learn More
          </Link>
        </div>
        <div
          className="ceo-container"
          style={{
            backgroundImage: `url(${homeData.ceoQuote.references[0].file.url})`,
          }}
        >
          <div className="ceo-quote">
            {renderRichText(homeData.ceoQuote, {
              [BLOCKS.PARAGRAPH]: (node, children) => (
                <p className="section-summary align-center">{children}</p>
              ),
            })}
          </div>
        </div>
        <div
          className="stats-container"
          style={{
            backgroundImage: `url(${homeData.stats.references[0].file.url})`,
          }}
        >
          <div className="stats-header">
            {renderRichText(homeData.stats, {
              [BLOCKS.HEADING_2]: (node, children) => (
                <h2 className="home-header">{children}</h2>
              ),
            })}
            <p className="asterisk">*</p>
          </div>
          <div className="hr"></div>
          <div className="block-container">
            {homeData.statBlocks.references.map(block => (
              <div className="stat-block" key={block.id}>
                <h6 className="home-header">{block.header}</h6>
                <h1 className="home-header">
                  {block.prefix}
                  <Counter
                    className="count"
                    end={block.amountDigit}
                    decimalAmount={block.decimalAmount}
                  />
                  {block.suffix}
                </h1>
                <h6 className="home-header">{block.subHeader}</h6>
              </div>
            ))}
          </div>
          <h5
            style={{
              marginBottom: "0px",
              marginLeft: "auto",
              paddingRight: "5px",
              alignSelf: "flex-end",
              color: "white",
            }}
          >
            *Numbers as of 2023
          </h5>
        </div>
      </div>
      <div className="our-people-container">
        {renderRichText(homeData.ourPeople, {
          [BLOCKS.HEADING_2]: (node, children) => (
            <h2 className="home-header">{children}</h2>
          ),
          [BLOCKS.PARAGRAPH]: (node, children) => (
            <p className="section-summary align-center">{children}</p>
          ),
        })}
        <div className="image-container">
          <img
            className="shadow"
            src={homeData.ourPeople.references[0].file.url}
            alt={homeData.ourPeople.references[0].file.fileName}
          />
        </div>
        <div className="learn-more-container">
          <a
            className="learn-more-button"
            href={homeData.snapshot}
            target="_blank"
            rel="noreferrer"
          >
            Learn More
          </a>
        </div>
      </div>
    </Layout>
  )
}
export default IndexPage

export const pageQuery = graphql`
  {
    allContentfulHome {
      edges {
        node {
          ceo {
            raw
            references {
              personTitle
              personName
              personPicture {
                file {
                  url
                  fileName
                }
              }
              id
            }
          }
          heroHeader {
            references {
              ...ContentfulAssetFragment
              fluid(maxWidth: 0) {
                ...ContentfulFluidFragment
              }
            }
          }
          missionStatement {
            raw
          }
          ourPeople {
            raw
            references {
              file {
                url
                fileName
              }
              title
              description
              id
            }
          }
          slug
          snapshot
          stats {
            raw
            references {
              title
              description
              file {
                url
                fileName
              }
              id
            }
          }
          title
          heroBrands {
            ...ContentfulAssetFragment
            fluid(maxWidth: 0) {
              ...ContentfulFluidFragment
            }
          }
          ceoQuote {
            raw
            references {
              file {
                url
                fileName
              }
              title
              id
            }
          }
          statBlocks {
            raw
            references {
              id
              header
              subHeader
              amountDigit
              prefix
              suffix
              decimalAmount
            }
          }
          sitePro {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                title
                description
                gatsbyImageData(width: 200)
                __typename
              }
            }
          }
          pwd {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                title
                description
                gatsbyImageData(width: 200)
                __typename
              }
            }
          }
          briggsEquipment {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                title
                description
                gatsbyImageData(width: 200)
                __typename
              }
            }
          }
          briggsTruckAndRail {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                title
                description
                gatsbyImageData(width: 200)
                __typename
              }
            }
          }
          briggsEarthAndAg {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                title
                description
                gatsbyImageData(width: 200)
                __typename
              }
            }
          }
          syncStorage {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                title
                description
                gatsbyImageData(width: 200)
                __typename
              }
            }
          }
        }
      }
    }
  }

  fragment ContentfulFluidFragment on ContentfulFluid {
    src
  }

  fragment ContentfulAssetFragment on ContentfulAsset {
    fluid(maxWidth: 0) {
      ...ContentfulFluidFragment
    }
    description
    title
    contentful_id
    id
  }
`
